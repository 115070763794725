@font-face {
  font-family: 'Baloo 2';
  src: url('./fonts/Baloo2-Regular.ttf') format('truetype');
}

body {
  padding: 0;
  margin: 0;
  text-align: center;
  background: #000;
  color: #fff;
  font-family: 'Baloo 2', sans-serif;
  font-size: 24px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
div.timer {
  position: absolute;
  left: 0;
  width: 100%;
  top: 8px;
  font-size: 14px;
}
h1 {
  font-size: 24px;
  font-weight: 100;
}
span.emailAddress {
  position: absolute;
  font-size: 14px;
  text-align: center;
  bottom: 30px;
  left: 0;
  width: 100%;
  cursor: pointer;
}
span.time {
  position: absolute;
  font-size: 14px;
  text-align: center;
  bottom: 8px;
  left: 0;
  width: 100%;
}
span.emailAddress a {
  color: #fff;
  text-decoration: none;
}
*::selection {
  background: #ffff43;
  color: #000;
}
